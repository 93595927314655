<template>
  <div>
    <h1>公式生成器</h1>

    <!-- 子公式部分 -->
    <h2>子公式管理</h2>
    <div v-for="(subFormula, index) in subFormulas" :key="index" class="formula-section">
      <h3>子公式 {{ index + 1 }}</h3>
      <input v-model="subFormula.name" placeholder="请输入子公式名称" />

      <!-- 子公式的参数 -->
      <draggable v-model="subFormula.parameters" group="params" @end="updateOrder">
        <div v-for="(param, i) in subFormula.parameters" :key="i" class="param-section">
          <label>参数名：</label>
          <input v-model="param.name" placeholder="参数名称" />

          <label>运算符：</label>
          <select v-model="param.operator">
            <option value="+">+</option>
            <option value="-">-</option>
            <option value="*">*</option>
            <option value="/">/</option>
          </select>

          <label>来源：</label>
          <select v-model="param.source">
            <option value="user_input">用户输入</option>
            <option value="database">数据库</option>
            <option value="api">API</option>
            <option value="constant">常量</option>
          </select>

          <input v-if="param.source === 'user_input'" v-model="param.value" placeholder="值" />
          <input v-if="param.source === 'database'" v-model="param.dbField" placeholder="数据库字段" />
          <input v-if="param.source === 'api'" v-model="param.apiUrl" placeholder="API URL" />
          <input v-if="param.source === 'constant'" v-model="param.constantValue" placeholder="常量值" />

          <button @click="removeSubParam(index, i)">删除参数</button>
        </div>
      </draggable>
      <button @click="addSubParam(index)">添加子公式参数</button>
    </div>
    <button @click="addSubFormula">添加子公式</button>

    <!-- 主公式部分 -->
    <h2>主公式管理</h2>
    <div class="formula-section">
      <label>主公式名称：</label>
      <input v-model="formulaName" placeholder="请输入主公式名称" />

      <!-- 主公式的参数 -->
      <draggable v-model="parameters" group="params" @end="updateOrder">
        <div v-for="(param, index) in parameters" :key="index" class="param-section">
          <label>参数名：</label>
          <input v-model="param.name" placeholder="参数名称" />

          <label>运算符：</label>
          <select v-model="param.operator">
            <option value="+">+</option>
            <option value="-">-</option>
            <option value="*">*</option>
            <option value="/">/</option>
          </select>

          <label>来源：</label>
          <select v-model="param.source">
            <option value="user_input">用户输入</option>
            <option value="database">数据库</option>
            <option value="api">API</option>
            <option value="subformula">子公式</option>
            <option value="constant">常量</option>
          </select>

          <input v-if="param.source === 'user_input'" v-model="param.value" placeholder="值" />
          <input v-if="param.source === 'database'" v-model="param.dbField" placeholder="数据库字段" />
          <input v-if="param.source === 'api'" v-model="param.apiUrl" placeholder="API URL" />
          <input v-if="param.source === 'constant'" v-model="param.constantValue" placeholder="常量值" />
          <select v-if="param.source === 'subformula'" v-model="param.subFormula">
            <option v-for="subFormula in subFormulas" :key="subFormula.name" :value="subFormula.name">
              {{ subFormula.name }}
            </option>
          </select>

          <button @click="removeParam(index)">删除参数</button>
        </div>
      </draggable>
      <button @click="addParam">添加主公式参数</button>
    </div>

    <!-- 生成 JSON 按钮 -->
    <button @click="generateJson">生成 JSON</button>

    <!-- 显示生成的 JSON -->
    <h2>生成的 JSON</h2>
    <pre>{{ generatedJson }}</pre>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  data() {
    return {
      formulaName: "", // 主公式名称
      parameters: [], // 主公式参数
      subFormulas: [], // 子公式列表
      generatedJson: "" // 生成的 JSON
    };
  },
  methods: {
    // 添加主公式参数
    addParam() {
      this.parameters.push({ name: "", operator: "+", source: "user_input", value: "", dbField: "", apiUrl: "", subFormula: "", constantValue: "" });
    },
    // 删除主公式参数
    removeParam(index) {
      this.parameters.splice(index, 1);
    },
    // 添加子公式
    addSubFormula() {
      this.subFormulas.push({ name: "", parameters: [] });
    },
    // 添加子公式参数
    addSubParam(subIndex) {
      this.subFormulas[subIndex].parameters.push({ name: "", operator: "+", source: "user_input", value: "", dbField: "", apiUrl: "", constantValue: "" });
    },
    // 删除子公式参数
    removeSubParam(subIndex, paramIndex) {
      this.subFormulas[subIndex].parameters.splice(paramIndex, 1);
    },
    // 更新参数的顺序
    updateOrder() {
      // 拖动结束后更新顺序
    },
    // 生成 JSON
    generateJson() {
      const json = {
        formulas: {
          [this.formulaName]: this.parameters.map(param => `${param.operator} {${param.name}}`).join(" ")
        },
        parameters: {}
      };

      // 添加主公式参数
      this.parameters.forEach(param => {
        if (param.source === "user_input") {
          json.parameters[param.name] = { source: "user_input", value: param.value };
        } else if (param.source === "database") {
          json.parameters[param.name] = { source: "database", params: { field: param.dbField } };
        } else if (param.source === "api") {
          json.parameters[param.name] = { source: "api", params: { url: param.apiUrl } };
        } else if (param.source === "constant") {
          json.parameters[param.name] = { source: "constant", value: param.constantValue };
        }
      });

      // 添加子公式
      this.subFormulas.forEach(subFormula => {
        const subFormulaJson = subFormula.parameters.map(param => `${param.operator} {${param.name}}`).join(" ");
        json.formulas[subFormula.name] = subFormulaJson;

        subFormula.parameters.forEach(param => {
          if (param.source === "user_input") {
            json.parameters[param.name] = { source: "user_input", value: param.value };
          } else if (param.source === "database") {
            json.parameters[param.name] = { source: "database", params: { field: param.dbField } };
          } else if (param.source === "api") {
            json.parameters[param.name] = { source: "api", params: { url: param.apiUrl } };
          } else if (param.source === "constant") {
            json.parameters[param.name] = { source: "constant", value: param.constantValue };
          }
        });
      });

      this.generatedJson = JSON.stringify(json, null, 2);
    }
  }
};
</script>

<style scoped>
.formula-section {
  margin-bottom: 20px;
}

.param-section {
  margin-bottom: 10px;
}

button {
  margin: 5px;
}
</style>
